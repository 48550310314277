// @ts-nocheck
import merge from 'lodash/merge';
import createTheme from './base/createTheme';
import createHeroVariants from './createHeroVariants';
import createCardVariants from './createCardVariants';
import createCollectionVariants from './createCollectionVariants';
import createSectionVariants from './createSectionVariants';
import createTextVariants from './createTextVariants';
import { createBreakpoints } from '@mui/system';

declare module '@mui/material/styles/createPalette' {
  export interface TypeBackground {
    light: string;
  }
}

const breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 684,
    md: 768,
    lg: 1024,
    xl: 1440
  }
});

const defaultTheme = {
  spacing: 8,
  breakpoints: {
    values: {
      xs: 0,
      sm: 684,
      md: 768,
      lg: 1024,
      xl: 1440
    }
  },
  typography: {
    fontSize: 22,
    fontFamily: "'Source Sans Pro', sans-serif",
    fontWeight: 400,
    fontWeight: {
      main: 400,
      extraLight: 200,
      light: 300,
      semiBold: 600,
      bold: 700
    },
    button: {
      fontFamily: "'Source Sans Pro', sans-serif",
      fontSize: 20
    },
    h1: {
      fontSize: 68,
      lineHeight: 'normal',
      [breakpoints.down('md')]: {
        fontSize: 46
      }
    },
    h2: {
      fontSize: 48,
      lineHeight: 1,
      fontWeight: 300,
      [breakpoints.down('md')]: {
        fontSize: 26
      }
    },
    h3: {
      fontSize: 28,
      lineHeight: 1.1,
      fontWeight: 600,
      [breakpoints.down('md')]: {
        fontSize: 22
      }
    },
    h4: {
      fontSize: 24,
      lineHeight: 1.4,
      fontWeight: 300,
      [breakpoints.down('md')]: {
        fontSize: 20
      }
    },
    h5: {
      fontSize: 16,
      lineHeight: 1,
      letterSpacing: 5,
      fontWeight: 600,
      textTransform: 'uppercase',
      [breakpoints.down('md')]: {
        fontSize: 12,
        letterSpacing: 4
      }
    },
    h6: {
      fontSize: 38,
      lineHeight: 1,
      fontWeight: 600
    },
    body1: {
      fontSize: 20,
      lineHeight: 1.4,
      fontWeight: 300,
      [breakpoints.down('md')]: {
        fontSize: 17
      }
    },
    body2: {
      fontSize: 18
    }
  },
  palette: {
    grey: {
      50: '#7C8184',
      100: '#F3F0F0',
      200: '#C4C4C4',
      300: '#B0B0B0',
      400: '#7C8184',
      900: '#000000'
    },
    background: {
      default: '#FFFFFF',
      dark: '#262730',
      light: '#F6F6F6'
    },
    primary: {
      lighter: '#20AA9C',
      light: '#F4FBFA',
      main: '#157A70',
      dark: '#156058',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#FFFFFF',
      light: '#E3F8F5',
      dark: '#006b87',
      contrastText: '#157A70'
    },
    tertiary: {
      light: '#E7F2F2',
      main: '#157A70',
      contrastText: '#2E2F38'
    },
    quartiary: {
      main: '#22AB9D'
    },
    text: {
      primary: '#157A70',
      secondary: '#262730'
    },
    action: {
      active: '#157A70',
      hover: '#F6F6F6',
      selected: 'rgba(196,196,196,0.15)'
    },
    error: {
      main: '#C94847'
    }
  }
};

// NOTE: Filters generated from primary color run through this tool:
// https://codepen.io/sosuke/pen/Pjoqqp
export const schemes = {
  'App - Teal': {
    palette: {
      primary: {
        light: '#F4FBFA',
        main: '#157A70',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: '#157A70'
      },
      secondary: {
        main: '#FFFFFF',
        contrastText: '#157A70'
      }
    }
  },
  'Store - Slate': {
    palette: {
      primary: {
        light: '#F5F7F8',
        main: '#3A667B',
        contrastText: '#FFFFFF'
      },
      text: {
        primary: '#3A667B'
      },
      secondary: {
        main: '#FFFFFF',
        contrastText: '#3A667B'
      }
    }
  },
  'Ad - Red': {
    palette: {
      primary: {
        light: '#FCF6F6',
        main: '#C94847',
        contrastText: '#FFFFFF'
      },
      secondary: {
        main: '#FFFFFF',
        contrastText: '#C94847'
      },
      text: {
        primary: '#C94847'
      }
    }
  },
  'Usage - Gold': {
    palette: {
      primary: {
        light: '#FCFCF6',
        main: '#C6BD47',
        contrastText: '#FFFFFF'
      },
      secondary: {
        main: '#FFFFFF',
        contrastText: '#C6BD47'
      },
      text: {
        primary: '#C6BD47'
      }
    }
  },
  'Consumer - Blue': {
    palette: {
      primary: {
        light: '#FAFCFE',
        main: '#91C4E9',
        contrastText: '#FFFFFF'
      },
      secondary: {
        main: '#FFFFFF',
        contrastText: '#91C4E9'
      },
      text: {
        primary: '#91C4E9'
      }
    }
  },
  'Teardown - Green': {
    palette: {
      primary: {
        light: '#FAFCF8',
        main: '#A4C57B',
        contrastText: '#FFFFFF'
      },
      secondary: {
        main: '#FFFFFF',
        contrastText: '#A4C57B'
      },
      text: {
        primary: '#A4C57B'
      }
    }
  },
  'Path - Product - Teal': {
    palette: {
      primary: {
        light: '#F3F7F9',
        main: '#2D6984',
        contrastText: '#FFFFFF'
      },
      secondary: {
        main: '#FFFFFF',
        contrastText: '#2D6984'
      },
      text: {
        primary: '#2D6984'
      }
    }
  },
  'Path - Social - Blue': {
    palette: {
      primary: {
        light: '#F0FBFB',
        main: '#00A1AF',
        contrastText: '#FFFFFF'
      },
      secondary: {
        main: '#FFFFFF',
        contrastText: '#00A1AF'
      },
      text: {
        primary: '#00A1AF'
      }
    }
  },
  'Path - Video - Purple': {
    palette: {
      primary: {
        light: '#F7FCF9',
        main: '#6D4689',
        contrastText: '#FFFFFF'
      },
      secondary: {
        main: '#FFFFFF',
        contrastText: '#6D4689'
      },
      text: {
        primary: '#6D4689'
      }
    }
  },
  'Path - Display - Green': {
    palette: {
      primary: {
        light: '#F3FCF8',
        main: '#1DA071',
        contrastText: '#FFFFFF'
      },
      secondary: {
        main: '#FFFFFF',
        contrastText: '#1DA071'
      },
      text: {
        primary: '#1DA071'
      }
    }
  }
};

const createSchemeTheme = (schemeKey: string) => {
  // Theme like object
  const scheme = schemes[schemeKey];
  // If scheme is not found, return default theme
  const baseSchemeTheme: any = createTheme(merge(scheme ? { scheme: schemeKey } : {}, defaultTheme, scheme));

  // All theme customizations go in this function to support generating on the fly
  const schemeTheme = createTheme(
    merge({}, baseSchemeTheme, {
      // Inject creator function to use from ContentModule
      createSchemeTheme,
      typography: {
        h3: {
          color: baseSchemeTheme.palette.text.primary
        },
        body1: {
          color: baseSchemeTheme.palette.text.secondary
        }
      },
      // Theme customizations
      components: {
        Card: {
          variants: createCardVariants(baseSchemeTheme)
        },
        Hero: {
          defaultProps: {
            disableGutters: false
          },
          variants: createHeroVariants(baseSchemeTheme),
          styleOverrides: {
            root: {
              // Anti-Flicker
              'transition': 'opacity .3s, background-color .3s',
              'transitionDelay': '.2s',
              'div': {
                transition: 'opacity .3s, background-color .3s',
                transitionDelay: '.2s'
              },
              '.async-hide &': {
                backgroundColor: 'transparent',
                div: {
                  transition: '0s',
                  transitionDelay: '0s',
                  opacity: '0 !important'
                }
              },
              'display': 'flex',
              // TODO: Remove padding from base to simplify overriding
              '&': {
                paddingTop: baseSchemeTheme.spacing(12),
                paddingBottom: baseSchemeTheme.spacing(12)
              },
              '& h1': {
                textAlign: 'left',
                color: baseSchemeTheme.palette.secondary.main,
                fontWeight: 300
              },
              '& h2': {
                textAlign: 'left',
                color: baseSchemeTheme.palette.secondary.main,
                fontWeight: 'bold',
                fontSize: 68
              },
              'img': {
                width: '100%'
              },
              '& p': {
                color: baseSchemeTheme.palette.secondary.main,
                fontSize: 28,
                fontWeight: 300,
                lineHeight: '122%',
                textAlign: 'left'
              },
              '& [class*="Text-root"]': {
                paddingTop: baseSchemeTheme.spacing(2)
              },
              '& .MuiContainer-root': {
                [baseSchemeTheme.breakpoints.down('lg')]: {
                  '& h1': {
                    fontSize: 46,
                    lineHeight: '46.5px'
                  },
                  '& h2': {
                    fontSize: 46,
                    lineHeight: '46.5px'
                  },
                  '& p': {
                    fontSize: 20
                  }
                }
              },
              '& .MuiButton-root': {
                [baseSchemeTheme.breakpoints.up('md')]: {
                  marginRight: baseSchemeTheme.spacing(3)
                }
              }
            },
            actionsRoot: {
              justifyContent: 'space-between',
              [baseSchemeTheme.breakpoints.down('md')]: {
                flexDirection: 'column'
              }
            }
          }
        },
        Collection: {
          variants: createCollectionVariants(baseSchemeTheme),
          styleOverrides: {
            root: {
              '& [class*="Collection-introText"]': {
                // Override padding being added to all texts
                '& [class*="MuiTypography-root"]': {
                  paddingBottom: 0
                }
              }
            }
          }
        },
        Media: {
          defaultProps: {
            disableInlineSVG: true
          }
        },
        Header: {
          height: 64,
          styleOverrides: {
            root: {
              'backgroundColor': baseSchemeTheme.palette.secondary.main,
              '& img': {
                width: 180,
                height: 'auto'
              }
            },
            contentContainer: {
              'width': '100%',
              // Match the styles from MuiContainer below
              'maxWidth': baseSchemeTheme.breakpoints.values.xl,
              'margin': 'auto',
              'paddingLeft': baseSchemeTheme.spacing(4),
              'paddingRight': baseSchemeTheme.spacing(4),
              [baseSchemeTheme.breakpoints.up('sm')]: {
                paddingLeft: baseSchemeTheme.spacing(6),
                paddingRight: baseSchemeTheme.spacing(6)
              },
              [baseSchemeTheme.breakpoints.up('lg')]: {
                paddingLeft: baseSchemeTheme.spacing(10),
                paddingRight: baseSchemeTheme.spacing(10)
              },
              'height': 64,
              '& .MuiLink-root': {
                display: 'flex',
                alignItems: 'center',
                padding: 0
              },
              '& svg[class*="Header-logo"]': {
                maxHeight: 36
              },
              '& img[class*="Header-logo"]': {
                height: 33,
                width: 'auto'
              }
            }
          }
        },
        Section: {
          variants: createSectionVariants(baseSchemeTheme)
        },
        Text: {
          variants: createTextVariants(baseSchemeTheme),
          styleOverrides: {
            root: {
              '& [class*="MuiTypography-root"]': {
                paddingBottom: baseSchemeTheme.spacing(2)
              },
              [baseSchemeTheme.breakpoints.down('sm')]: {
                '& h2': {
                  fontSize: 39
                }
              },
              // Removes extra <br> tag being added by Contentful
              '& > br:last-child': {
                display: 'none'
              },
              '& > hr:first-child': {
                backgroundColor: baseSchemeTheme.palette.text.primary,
                margin: 0,
                marginBottom: baseSchemeTheme.spacing(3),
                width: 36,
                height: 2,
                border: 'none'
              },
              '& li > p[class*="MuiTypography-root"]': {
                marginBottom: baseSchemeTheme.spacing(2),
                paddingBottom: 0
              },

              'tr:first-child .MuiTypography-root': {
                fontWeight: 800,
                wordBreak: 'keep-all'
              },
              'table': {
                width: '100%',
                border: '1px solid',
                borderCollapse: 'collapse',
                td: {
                  'border': '1px solid',
                  'padding': '8px',
                  'wordBreak': 'break-word',
                  '*': {
                    padding: '0!important',
                    display: 'inline'
                  }
                }
              }
            }
          }
        },
        FormMarketoEmbed: {
          styleOverrides: {
            form: {
              'padding': `${baseSchemeTheme.spacing(2, 1)} !important`,
              'fontFamily': `${baseSchemeTheme.typography.fontFamily} !important`,
              'fontWeight': 500,
              'color': `${baseSchemeTheme.palette.primary.main} !important`,
              '& .mktoFormRow': {
                marginBottom: baseSchemeTheme.spacing(1)
              },
              '& .mktoFieldWrap': {
                borderRadius: `${baseSchemeTheme.spacing(1)} !important`,
                borderWidth: '2px !important'
              },
              '& .mktoButtonWrap': {
                '& button': {
                  backgroundColor: `${baseSchemeTheme.palette.secondary.main} !important`
                }
              },
              '& input': {
                'height': 'auto !important',
                'fontSize': '23px !important',
                '&::placeholder': {
                  opacity: '1 !important'
                }
              },
              '& [class*="-select"]': {
                'position': 'relative',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  zIndex: -1,
                  top: 24,
                  right: 22,
                  width: 0,
                  height: 0,
                  borderLeft: '10px solid transparent',
                  borderRight: '10px solid transparent',
                  borderTop: '16px solid white'
                }
              },
              '& select': {
                'width': '100% !important',
                'height': '65px !important',
                'padding': '0 10px !important',
                'backgroundColor': 'transparent',
                'border': 0,
                'color': 'white !important',
                'fontFamily': '"Source Sans Pro", sans-serif !important',
                'fontSize': '22px !important',
                'fontWeight': 500,
                'letterSpacing': '0.5px',
                'WebkitAppearance': 'none',
                'MozAppearance': 'none',
                '& option': {
                  color: 'black !important',
                  fontFamily: '"Source Sans Pro", sans-serif !important'
                }
              },
              '& button': {
                height: 70,
                borderRadius: `${baseSchemeTheme.spacing(1)} !important`,
                boxShadow: 'none !important',
                fontSize: '23px !important',
                fontWeight: 500,
                [baseSchemeTheme.breakpoints.up('lg')]: {
                  fontSize: '35px !important'
                }
              }
            }
          }
        },
        // MUI overrides
        MuiContainer: {
          styleOverrides: {
            root: {
              'paddingLeft': baseSchemeTheme.spacing(4),
              'paddingRight': baseSchemeTheme.spacing(4),
              [baseSchemeTheme.breakpoints.up('sm')]: {
                paddingLeft: baseSchemeTheme.spacing(6),
                paddingRight: baseSchemeTheme.spacing(6)
              },
              [baseSchemeTheme.breakpoints.up('lg')]: {
                paddingLeft: baseSchemeTheme.spacing(10),
                paddingRight: baseSchemeTheme.spacing(10)
              },
              '& .MuiContainer-disableGutters': {
                paddingLeft: 0,
                paddingRight: 0
              }
            }
          }
        },
        MuiTypography: {
          styleOverrides: {
            root: {
              'transition': 'opacity .2s',

              '.async-hide &': {
                opacity: '0 !important'
              }
            }
          }
        },
        MuiButton: {
          variants: [
            {
              props: {
                variant: 'nav'
              },
              style: {
                'minWidth': 'auto',
                'height': 'auto',
                'marginTop': baseSchemeTheme.spacing(1),
                'marginBottom': baseSchemeTheme.spacing(1),
                'paddingTop': baseSchemeTheme.spacing(0.25),
                'paddingBottom': baseSchemeTheme.spacing(0.25),
                'borderRadius': 6,
                'color': baseSchemeTheme.palette.action.active,
                'fontSize': 16,
                'fontWeight': 600,
                'letterSpacing': '0',
                'textTransform': 'capitalize',
                ':disabled': {
                  backgroundColor: baseSchemeTheme.palette.action.hover,
                  color: baseSchemeTheme.palette.action.active
                },
                ':hover': {
                  backgroundColor: baseSchemeTheme.palette.action.hover,
                  color: baseSchemeTheme.palette.action.active
                }
              }
            },
            {
              props: {
                variant: 'language'
              },
              style: {
                color: baseSchemeTheme.palette.secondary.main,
                textTransform: 'uppercase',
                letterSpacing: '0.2rem',
                fontSize: 15,
                fontWeight: 600,
                border: `2px solid ${baseSchemeTheme.palette.secondary.main}`,
                height: 'auto',
                minWidth: 'auto',
                width: 240,
                [baseSchemeTheme.breakpoints.down('md')]: {
                  borderRadius: 10
                }
              }
            }
          ],
          defaultProps: {
            disableElevation: true
            // color: baseSchemeTheme?.scheme ? 'secondary' : undefined
          },
          styleOverrides: {
            root: {
              minWidth: 290,
              height: 56,
              borderRadius: 10,
              fontWeight: 600,
              letterSpacing: 5,
              fontSize: 18,
              [baseSchemeTheme.breakpoints.down('md')]: {
                width: '100%',
                minWidth: 'auto',
                height: 43,
                marginBottom: baseSchemeTheme.spacing(2),
                borderRadius: 21.5,
                fontSize: 16
              }
            },
            containedPrimary: {
              // color: baseSchemeTheme?.scheme
              //   ? baseSchemeTheme.palette.secondary.contrastText
              //   : baseSchemeTheme.palette.secondary.main
              '&:hover': {
                backgroundColor: `${baseSchemeTheme.palette.primary.main}80`
              }
            },
            containedSecondary: {
              // color: baseSchemeTheme.palette.primary.main,
              '&:hover': {
                backgroundColor: `${baseSchemeTheme.palette.secondary.main}80`,
                color: baseSchemeTheme.palette.secondary.main
              }
            },
            outlined: {
              'borderColor': baseSchemeTheme.palette.secondary.main,
              'color': baseSchemeTheme.palette.secondary.main,
              'borderWidth': 2,
              '&:hover': {
                borderWidth: 2,
                ...(schemeKey && {
                  borderColor: baseSchemeTheme.palette.secondary.main,
                  backgroundColor: baseSchemeTheme.palette.secondary.main,
                  color: baseSchemeTheme.palette.primary.main
                })
              }
            }
          }
        },
        MuiGrid: {
          styleOverrides: {
            root: {
              [baseSchemeTheme.breakpoints.down('md')]: {
                '&.MuiGrid-spacing-xs-15': {
                  'marginTop': baseSchemeTheme.spacing(-5),
                  '> .MuiGrid-item': {
                    paddingTop: baseSchemeTheme.spacing(5)
                  }
                }
              }
            }
          }
        },
        MuiAvatar: {
          styleOverrides: {
            root: {
              backgroundColor: baseSchemeTheme.palette.primary.light
            }
          }
        },
        MuiList: {
          defaultProps: {
            dense: true,
            disablePadding: true
          }
        },
        MuiListSubheader: {
          styleOverrides: {
            root: {
              position: 'relative',
              paddingLeft: baseSchemeTheme.spacing(5),
              color: baseSchemeTheme.palette.text.primary,
              fontSize: 24,
              fontWeight: 300,
              lineHeight: baseSchemeTheme.spacing(4)
            }
          }
        },
        MuiListItem: {
          styleOverrides: {
            root: {
              padding: baseSchemeTheme.spacing(0.5, 0)
            }
          }
        },
        MuiListItemButton: {
          styleOverrides: {
            root: {
              padding: baseSchemeTheme.spacing(0, 5),
              [baseSchemeTheme.breakpoints.down('md')]: {
                'paddingLeft': 0,
                '&:hover': {
                  backgroundColor: 'transparent'
                }
              }
            }
          }
        },
        MuiListItemText: {
          styleOverrides: {
            primary: {
              color: baseSchemeTheme.palette.primary.main,
              fontSize: 14,
              fontWeight: 600,
              letterSpacing: '1.2px',
              textTransform: 'uppercase',
              [baseSchemeTheme.breakpoints.down('lg')]: {
                color: baseSchemeTheme.palette.secondary.main,
                fontSize: 15,
                fontWeight: 400
              }
            },
            secondary: {
              color: baseSchemeTheme.palette.quartiary?.main,
              fontSize: 15,
              fontWeight: 300
            }
          }
        },
        MuiListItemAvatar: {
          styleOverrides: {
            root: {
              'minWidth': 52,
              '& img': {
                objectFit: 'cover',
                width: '100%'
              },
              '& .MuiAvatar-root': {
                height: 34,
                width: 34
              },
              [baseSchemeTheme.breakpoints.down('md')]: {
                minWidth: baseSchemeTheme.spacing(3)
              }
            }
          }
        }
      }
    })
  );
  return schemeTheme;
};

const theme = createSchemeTheme();

export default theme;
